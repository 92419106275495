<template>
  <div class="py-10 max-w-7xl mx-auto sm:px-6 lg:px-8 text-center">
    <h1 class="text-4xl tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-6xl">404<br />Page not found</h1>
  </div>
  <main>
    <div class="container max-w-7xl mx-auto sm:px-6 lg:px-8 text-center">
      <p class="mb-8">Oh no, we can't find this page.</p>
      <img class="mx-auto" src="@/assets/404.png"/>
    </div>
  </main>
</template>
